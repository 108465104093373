import { NgModule } from "@angular/core";
import {
  PreloadAllModules,
  Routes,
  Router,
  RouterModule,
} from "@angular/router";

//Pages
import { TestComponent } from "./pages/test/test.component";
import { Test2Component } from "./pages/test2/test2.component";
import { WelcomeComponent } from "./pages/welcome/welcome.component";
import { TermsComponent } from "./pages/terms/terms.component";
import { AccessDeniedComponent } from "./pages/access-denied/access-denied.component";
import { GettingStartedComponent } from "./pages/getting-started/getting-started.component";
import { KycComponent } from "./pages/kyc/kyc.component";
import {KysVerificationComponent} from "./pages/kys-verification/kys-verification.component";

//Components
import { IdCheckComponent } from "./components/id-check/id-check.component";
import { AddressCheckComponent } from "./components/address-check/address-check.component";
import { SelfieCheckComponent } from "./components/selfie-check/selfie-check.component";
import { PreviewComponent } from "./components/preview/preview.component";

//Services
import { UiStateService } from "./services/ui-state.service";

//Guards
import { AuthGuard } from "./guards/auth.guard";
import { KycGuard } from "./guards/kyc.guard";
import { DeactivateGuard } from "./guards/deactivate.guard";
import { KysRetailFeasComponent } from "./pages/kys-retail-feas/kys-retail-feas.component";
import { Welcome2Component } from "./pages/welcome2/welcome2.component";
import { KysCorporateFeasComponent } from "./pages/kys-corporate-feas/kys-corporate-feas.component";
import { AddDetailsComponent } from "./pages/kys-corporate-feas/corp-control-officers/add-details/add-details.component";
import { CorpControlOfficersComponent } from "./pages/kys-corporate-feas/corp-control-officers/corp-control-officers.component";
import { KysControlOfficersFeasComponent } from "./pages/kys-control-officers-feas/kys-control-officers-feas.component";
import { QuistionairPageComponent } from "./pages/quistionair-page/quistionair-page.component";

const desktop_routes: Routes = [
  { path: "", redirectTo: "startkyc", pathMatch: "full" },
  { path: "", component: WelcomeComponent, pathMatch: "full" },
  {
    path: "terms",
    component: TermsComponent,
    pathMatch: "full",
    canActivate: [AuthGuard],
  },
  {
    path: "getting-started",
    component: GettingStartedComponent,
    pathMatch: "full",
    canActivate: [AuthGuard],
  },
  {
    path: "questionair-page",
    component: QuistionairPageComponent,
    pathMatch: "full",
    // canActivate: [AuthGuard],
  },
  {
    path: "kys-verification",
    component: KysVerificationComponent,
    pathMatch: "full",
    canActivate: [AuthGuard],
  },
  {
    path: "kys-retail-feas",
    component: KysRetailFeasComponent,
    pathMatch: "full",
    canActivate: [AuthGuard],
  },
  {
    path: "kys-corporate-feas",
    component: KysCorporateFeasComponent,
    pathMatch: "full",
    canActivate: [AuthGuard],
  },
  {
    path: "kys-control-officers-feas",
    component: KysControlOfficersFeasComponent,
    pathMatch: "full",
    canActivate: [AuthGuard],
  },
  {
    path: "add-details",
    component: AddDetailsComponent,
    //pathMatch: "full",
    //canActivate: [AuthGuard],
  },
  {
    path: "welcome",
    component: Welcome2Component,
    pathMatch: "full",
    // canActivate: [AuthGuard],
  },
  {
    path: "corp-officers",
    component: CorpControlOfficersComponent,
    pathMatch: "full",
    canActivate: [AuthGuard],
  },
  {
    path: "kyc",
    component: KycComponent,
    canActivate: [AuthGuard],
    children: [
      { path: "id-check", component: IdCheckComponent },
      { path: "address-check", component: AddressCheckComponent },
      { path: "face-check", component: SelfieCheckComponent },
      { path: "preview", component: PreviewComponent },
    ],
  },
  {
    path: "access-denied",
    component: AccessDeniedComponent,
    pathMatch: "full",
  },
  { path: "test", redirectTo: "test1", pathMatch: "full" },
  { path: "test1", component: TestComponent, pathMatch: "full" },
  { path: "**", redirectTo: "" },
];

const mobile_routes: Routes = [
  { path: "", redirectTo: "startkyc", pathMatch: "full" },
  { path: "", component: Welcome2Component, pathMatch: "full" },
  {
    path: "terms",
    component: TermsComponent,
    pathMatch: "full",
    canActivate: [AuthGuard],
  },
  {
    path: "getting-started",
    component: GettingStartedComponent,
    pathMatch: "full",
    canActivate: [AuthGuard],
  },
  {
    path: "kyc",
    component: KycComponent,
    canActivate: [AuthGuard],
    children: [
      { path: "id-check", component: IdCheckComponent },
      { path: "address-check", component: AddressCheckComponent },
      { path: "face-check", component: SelfieCheckComponent },
      { path: "preview", component: PreviewComponent },
    ],
  },
  {
    path: "access-denied",
    component: AccessDeniedComponent,
    pathMatch: "full",
  },
  { path: "test", redirectTo: "test2", pathMatch: "full" },
  { path: "test2", component: Test2Component, pathMatch: "full" },
  { path: "**", redirectTo: "" },
];

@NgModule({
  // imports: [RouterModule.forRoot(routes)],
  imports: [
    RouterModule.forRoot(desktop_routes, {
      preloadingStrategy: PreloadAllModules,
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {
  public constructor(
    private router: Router,
    private applicationStateService: UiStateService
  ) {
    if (applicationStateService.getIsMobileResolution()) {
      router.resetConfig(mobile_routes);
    }
  }
}
