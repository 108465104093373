import { Component, Input, OnInit } from '@angular/core';
import { DataService } from 'src/app/services/data.service';
import { AppService } from 'src/app/services/app.service';
import { ActivatedRoute, Router } from '@angular/router';
import { FormBuilder, } from '@angular/forms';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { ToastrService } from 'ngx-toastr';
import { of } from 'rxjs';
import { finalize } from 'rxjs/operators';

export interface User {
  name: string;
  email: string;
  completed: boolean;
}


@Component({
  selector: 'app-corp-control-officers',
  templateUrl: './corp-control-officers.component.html',
  styleUrls: ['./corp-control-officers.component.scss']
})
export class CorpControlOfficersComponent implements OnInit {
  uboUsers: User[] = [];
  directors: User[] = [];
  complianceOfficer: User[] = [];
  countries: any;
  nameUBO: any;
  emailUBO: any;
  officerID1: any;
  selectedDocument: any;
  officerID2: any;
  controlOfficerType: any;
  emailDirector: any;
  nameDirector: any;
  nameOfficer: any;
  emailOfficer: any;
  isTrue: any = "";
  UBOId: any;

  @Input() checks:any;
  basicDone: boolean=false;
  digitalDone: boolean=false;
  questionnaireDone: boolean=false;
  uboDone: boolean=false;

  constructor(
    private formBuilder: FormBuilder,
    private _data: DataService,
    private _app: AppService,
    public _router: Router,
    public route: ActivatedRoute,
    private http: HttpClient,
    private toastrService: ToastrService
  ) { }

  ngOnInit() {;
    console.log("checks: ",this.checks)
    this.loadUbo();
    this.loadDirector();
    this.loadComplianceOfficer();
    this._data.basicValue.subscribe(res=>{
      this.basicDone=res
    })
    this._data.digitalValue.subscribe(res=>{
      this.digitalDone=res
    })
    this._data.questionValue.subscribe(res=>{
      this.questionnaireDone=res
    })
    this._data.uboValue.subscribe(res=>{
      this.uboDone=res
    })
  }

  isDoc(val) {
    this.isTrue = val
  }

  close() {
    this.selectedDocument = null
  }

  onClose() {
    if (this.selectedDocument === "UBO") {
      const user = this.uboUsers.find(({ email }) => email === this.emailUBO);
      user.completed = true;
    }
    if (this.selectedDocument === "DIRECTOR") {
      const user = this.directors.find(({ email }) => email === this.emailDirector);
      user.completed = true;
    }

    if (this.selectedDocument === "OFFICER") {
      const user = this.complianceOfficer.find(({ email }) => email === this.emailOfficer);
      user.completed = true;
    }
    this.selectedDocument = null;
  }

  loadUbo() {
    this._app.getUBO().subscribe((ubos: any[]) => {
      this.uboUsers = ubos.map(({ firstName, email }) => ({ name: firstName, email: email, completed: true }));
      if (!ubos.length) {
        this.uboUsers = [{ name: '', email: '', completed: false }];
      }
    });
  }

  loadDirector() {
    this._app.getDirectors().subscribe((directors) => {
      this.directors = directors.map(({ firstName, email }) => ({ name: firstName, email: email, completed: true }))
      if (!directors.length) {
        this.directors = [{ name: '', email: '', completed: false }];
      }
    });
  }

  loadComplianceOfficer() {
    this._app.getComplianceOfficer().subscribe((complianceOfficers) => {
      this.complianceOfficer = complianceOfficers.map(({ firstName, email }) => ({ name: firstName, email: email, completed: true }))
      if (!complianceOfficers.length) {
        this.complianceOfficer = [{ name: '', email: '', completed: false }];
      }
    });
  }

  addUbo(name: string, email: string, row: User) {
    const payload = {
      "firstName": name,
      "lastName": null,
      "controlOfficerType": "UBO",
      "maritalStatus": null,
      "dob": null,
      "phoneNumber": null,
      "email": email,
      "residenceAddress": null,
      "ssn": null,
      "taxId": null,
      "percentageOfOwnership": null,
      "currentStatus": null,
      "designation": null,
      "citizenshipCountryCode": ""
    };

    this._app.getDetailsOfficers(payload).subscribe((data) => {
      this.toastrService.success('UBO added');
      this.controlOfficerType = data["data"].controlOfficerType
      this.nameUBO = data["data"].firstName
      this.emailUBO = data["data"].email
      this.UBOId = data["data"].id
      this._router.navigate([], { queryParams: { 'controlOfficerType': 'UBO' } });
      this.selectedDocument = "UBO";
      row.name = name;
      row.email = email;
      const payload = {
        "message": name + ' has been added as UBO',
        "page": "Control Officer Add",
        "geoLocation": null
      }
      let status={
        "kysTpeCheckHeading":"CONTROL_OFFICER",
        "kysTpeCheckStatus":"PASSED"
      }
      this._app.sendStatus(status).subscribe(response=>{
        this._app.getStatus().subscribe(res=>{
          this._data.assignStatus(res)
        })
      })
      this._app.feasAddActivityLog(localStorage.getItem("companyKysNo"), payload).subscribe();
    },
      (err: HttpErrorResponse) => {
        const payload = {
          "message": err,
          "page": "Control Officer Add",
          "geoLocation": null
        }
        this._app.feasAddActivityLog(localStorage.getItem("companyKysNo"), payload).subscribe();
      }
    )
  }

  addDirector(name: string, email: string, row: User) {
    let payload = {
      "firstName": name,
      "lastName": null,
      "controlOfficerType": "DIRECTOR",
      "maritalStatus": null,
      "dob": null,
      "phoneNumber": null,
      "email": email,
      "residenceAddress": null,
      "ssn": null,
      "taxId": null,
      "percentageOfOwnership": null,
      "currentStatus": null,
      "designation": null,
      "citizenshipCountryCode": ""
    }
    console.log("User Form: ", payload);
    this._app.getDetailsOfficers(payload).subscribe((data) => {
      this.toastrService.success('Director added');
      this.officerID1 = data["data"].id
      this.nameDirector = data["data"].firstName
      this.emailDirector = data["data"].email

      this._router.navigate([], { queryParams: { 'controlOfficerType': 'DIRECTOR' } });
      this.selectedDocument = "DIRECTOR";
      row.name = name;
      row.email = email;
      const payload = {
        "message": name + ' has been added as DIRECTOR',
        "page": "Control Officer Add",
        "geoLocation": null
      }
      let status={
        "kysTpeCheckHeading":"CONTROL_OFFICER",
        "kysTpeCheckStatus":"PASSED"
      }
      this._app.sendStatus(status).subscribe(response=>{
        this._app.getStatus().subscribe(res=>{
          this._data.assignStatus(res)
        })
      })
      this._app.feasAddActivityLog(localStorage.getItem("companyKysNo"), payload).subscribe();
    },
      (err: HttpErrorResponse) => {
        const payload = {
          "message": err,
          "page": "Control Officer Add",
          "geoLocation": null
        }
        this._app.feasAddActivityLog(localStorage.getItem("companyKysNo"), payload).subscribe();
      }
    )
  }


  // For OFFICER
  addComplianceOfficer(name: string, email: string, row: User) {
    const payload = {
      "firstName": name,
      "lastName": null,
      "controlOfficerType": "OFFICER",
      "maritalStatus": null,
      "dob": null,
      "phoneNumber": null,
      "email": email,
      "residenceAddress": null,
      "ssn": null,
      "taxId": null,
      "percentageOfOwnership": null,
      "currentStatus": null,
      "designation": null,
      "citizenshipCountryCode": ""
    };
    this._app.getDetailsOfficers(payload).subscribe((data) => {
      this.toastrService.success('Compliance officer added');
      this.officerID2 = data["data"].id
      this.nameOfficer = data["data"].firstName
      this.emailOfficer = data["data"].email

      this._router.navigate([], { queryParams: { 'controlOfficerType': 'OFFICER' } });
      this.selectedDocument = "OFFICER";
      row.name = name;
      row.email = email;
      const payload = {
        "message": name + ' has been added as OFFICER',
        "page": "Control Officer Add",
        "geoLocation": null
      }
      let status={
        "kysTpeCheckHeading":"CONTROL_OFFICER",
        "kysTpeCheckStatus":"PASSED"
      }
      this._app.sendStatus(status).subscribe(response=>{
        this._app.getStatus().subscribe(res=>{
          this._data.assignStatus(res)
        })
      })
      this._app.feasAddActivityLog(localStorage.getItem("companyKysNo"), payload).subscribe();
    },
      (err: HttpErrorResponse) => {
        const payload = {
          "message": err,
          "page": "Control Officer Add",
          "geoLocation": null
        }
        this._app.feasAddActivityLog(localStorage.getItem("companyKysNo"), payload).subscribe();
      }
    )
  }


  addComplianceEntry() {
    this.complianceOfficer.push({ name: '', email: '', completed: false })
  }

  addDirectorEntry() {
    this.directors.push({ name: '', email: '', completed: false })
  }

  addUboEntry() {
    this.uboUsers.push({ name: '', email: '', completed: false })
  }

  getFinalSubmit() {
    let abc;
    this._data.changeLoaderVisibility(true);
    this._app.getFinalSubmit(abc).pipe(finalize(() => this._data.changeLoaderVisibility(false))).subscribe((response) => {
      this._data.toastrShow(`All details submitted Successfully`, 'success');
      const payload = {
        "message": 'Company details submitted Successfully',
        "page": "Company Final Submit",
        "geoLocation": null
      }
      this._data.finishVal(true)
      this._app.feasAddActivityLog(localStorage.getItem("companyKysNo"), payload).subscribe();
    },
      (err: HttpErrorResponse) => {
        const payload = {
          "message": err,
          "page": "Company Final Submit",
          "geoLocation": null
        }
        this._app.feasAddActivityLog(localStorage.getItem("companyKysNo"), payload).subscribe();
      }
    );
  }
  
}
