import { Component, OnInit } from '@angular/core';
import { AppService } from 'src/app/services/app.service';
import { DataService } from 'src/app/services/data.service';

@Component({
  selector: 'app-kys-control-officers-feas',
  templateUrl: './kys-control-officers-feas.component.html',
  styleUrls: ['./kys-control-officers-feas.component.scss']
})
export class KysControlOfficersFeasComponent implements OnInit {

  checks: any;
  isSelfie;
  details:any;
  terms:boolean=true;
  checkbox = false;
  coFaceDone:boolean=false;
  coDigitalDone:boolean=false;
  coBasicDone:boolean=false;
  constructor(
    private _app: AppService,
    private _data: DataService
  ) { }

  ngOnInit() {
    this._data.cobasicValue.subscribe(res=>{
      this.coBasicDone=res
    })
    this._data.codigitalValue.subscribe(res=>{
      this.coDigitalDone=res
    })
    this._data.cofaceValue.subscribe(res=>{
      this.coFaceDone=res
    })
    this._app.getVideoChecks().subscribe(result => {
      this.checks = result["idvChecks"][0];
      console.log("this si checks",this.checks)
      this.isSelfie = ((this.checks['SELFIE'] && this.checks['SELFIE'].length > 0) || (this.checks['LIVELINESS'] && this.checks['LIVELINESS'].length > 0)) ? true : false;
    })
    this._data.uboDetailsValue.subscribe(res=>{
      this.details=res
    })

  }

}
