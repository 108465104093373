import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { DataService } from 'src/app/services/data.service';
import { AppService } from 'src/app/services/app.service';
import { ActivatedRoute, Router } from '@angular/router';
import { FormBuilder, FormGroup, Validators, } from '@angular/forms';
import { first } from 'rxjs/operators';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { pureObjectDef } from '@angular/core/src/view';

@Component({
  selector: 'app-add-details',
  templateUrl: './add-details.component.html',
  styleUrls: ['./add-details.component.scss']
})
export class AddDetailsComponent implements OnInit {

  @Input() name: string;
  @Input() email: string;
  @Input() name1: string;
  @Input() email1: string;
  @Input() name2: string;
  @Input() email2: string;
  @Input() UBOId;
  @Input() officerID1;
  @Input() officerID2;
  @Input() document;


  @Output()
  close = new EventEmitter();
  @Output() public isDoc = new EventEmitter<boolean>();
  reportUserName: string;
  individualInfoByAccountIdData: any;
  dob: any;
  addMoreDetailsForm: FormGroup;
  addMoreDetailsOfDirectorsForm: FormGroup;
  addMoreDetailsOfficersForm: FormGroup;
  formDataDetails: any;
  formDataDetails1: any;
  formDataDetails2: any;
  countries: any;
  param1: string;
  param2: string;
  param3: string;

  constructor(

    private formBuilder: FormBuilder,
    private _data: DataService,
    private _app: AppService,
    public router: Router,
    public route: ActivatedRoute,
    private http: HttpClient
  ) {
    this.route.queryParams.subscribe(params => {
      this.param1 = params['controlOfficerType'];
    });

  }

  ngOnInit() {

    this.isDoc.emit(true);
    this.getAllCountries()

    this.addMoreDetailsForm = this.formBuilder.group({
      lastName: [''],
      dob: [''],
      maritalStatus: [''],
      phoneNo: [''],
      address: [''],
      ssn: [''],
      taxID: [''],
      percentage: [''],
      status: [''],
      designation: [''],
      firstName: [this.name],
      email: [this.email],
      citizenship: ['', Validators.required]
    });
    this.addMoreDetailsOfDirectorsForm = this.formBuilder.group({
      lastName: [''],
      dob: [''],
      maritalStatus: [''],
      phoneNo: [''],
      address: [''],
      ssn: [''],
      taxID: [''],
      percentage: [''],
      status: [''],
      designation: [''],
      firstName: [this.name1],
      email: [this.email1],
      citizenship: ['', Validators.required]
    });
    this.addMoreDetailsOfficersForm = this.formBuilder.group({
      lastName: [''],
      dob: [''],
      maritalStatus: [''],
      phoneNo: [''],
      address: [''],
      ssn: [''],
      taxID: [''],
      percentage: [''],
      status: [''],
      designation: [''],
      firstName: [this.name2],
      email: [this.email2],
      citizenship: ['', Validators.required]
    });
  }


  closeInfo($event: MouseEvent) {
    $event.preventDefault();
    this.close.emit();
  }



  // For UBO

  getMoreDetails() {
    let addDetailsForm = {
      "firstName": this.name,
      "lastName": this.addMoreDetailsForm.value.lastName,
      "controlOfficerType": this.param1,
      "maritalStatus": this.addMoreDetailsForm.value.maritalStatus,
      "dob": this.addMoreDetailsForm.value.dob,
      "phoneNumber": parseInt(this.addMoreDetailsForm.value.phoneNo),
      "email": this.email,
      "residenceAddress": this.addMoreDetailsForm.value.address,
      "ssn": this.addMoreDetailsForm.value.ssn,
      "taxId": this.addMoreDetailsForm.value.taxID,
      "percentageOfOwnership": this.addMoreDetailsForm.value.percentage,
      "currentStatus": this.addMoreDetailsForm.value.status,
      "designation": this.addMoreDetailsForm.value.designation,
      "citizenshipCountryCode": this.addMoreDetailsForm.value.citizenship
    }
    console.log("User Form: ", addDetailsForm);
    this.getAddOfficersDetails(addDetailsForm)
  }

  getAddOfficersDetails(obj) {
    this._app.getMoreDetailsOfficers(obj).pipe(first()).subscribe((data) => {
      this.formDataDetails = data["data"]
      this._data.uboVal(true)
      
      this._data.toastrShow("Details have been submitted", `success`)
    })
  }

  // For DIRECTORS

  getMoreDetails1() {
    let addDetailsForm = {
      "firstName": this.name1,
      "lastName": this.addMoreDetailsOfDirectorsForm.value.lastName,
      "controlOfficerType": this.param1,
      "maritalStatus": this.addMoreDetailsOfDirectorsForm.value.maritalStatus,
      "dob": this.addMoreDetailsOfDirectorsForm.value.dob,
      "phoneNumber": parseInt(this.addMoreDetailsOfDirectorsForm.value.phoneNo),
      "email": this.email1,
      "residenceAddress": this.addMoreDetailsOfDirectorsForm.value.address,
      "ssn": this.addMoreDetailsOfDirectorsForm.value.ssn,
      "taxId": this.addMoreDetailsOfDirectorsForm.value.taxID,
      "percentageOfOwnership": this.addMoreDetailsOfDirectorsForm.value.percentage,
      "currentStatus": this.addMoreDetailsOfDirectorsForm.value.status,
      "designation": this.addMoreDetailsOfDirectorsForm.value.designation,
      "citizenshipCountryCode": this.addMoreDetailsOfDirectorsForm.value.citizenship
    }
    console.log("User Form: ", addDetailsForm);
    this.getAddOfficersDetails1(addDetailsForm)
  }

  getAddOfficersDetails1(obj) {
    this._app.getMoreDetailsOfficers(obj).pipe(first()).subscribe((data) => {
      this.formDataDetails1 = data["data"]
      this._data.uboVal(true)
      
      this._data.toastrShow("Details have been submitted", `success`)
    })
  }

  // For OFFICER

  getMoreDetails2() {
    let addDetailsForm = {
      "firstName": this.name2,
      "lastName": this.addMoreDetailsOfficersForm.value.lastName,
      "controlOfficerType": this.param1,
      "maritalStatus": this.addMoreDetailsOfficersForm.value.maritalStatus,
      "dob": this.addMoreDetailsOfficersForm.value.dob,
      "phoneNumber": parseInt(this.addMoreDetailsOfficersForm.value.phoneNo),
      "email": this.email2,
      "residenceAddress": this.addMoreDetailsOfficersForm.value.address,
      "ssn": this.addMoreDetailsOfficersForm.value.ssn,
      "taxId": this.addMoreDetailsOfficersForm.value.taxID,
      "percentageOfOwnership": this.addMoreDetailsOfficersForm.value.percentage,
      "currentStatus": this.addMoreDetailsOfficersForm.value.status,
      "designation": this.addMoreDetailsOfficersForm.value.designation,
      "citizenshipCountryCode": this.addMoreDetailsOfficersForm.value.citizenship
    }
    console.log("User Form: ", addDetailsForm);
    this.getAddOfficersDetails2(addDetailsForm)
  }

  getAddOfficersDetails2(obj) {
    this._app.getMoreDetailsOfficers(obj).pipe(first()).subscribe((data) => {
      this.formDataDetails2 = data["data"]
      this._data.uboVal(true)
      
      this._data.toastrShow("Details have been submitted", `success`)
    })
  }

  // Get All Couontries
  getAllCountries() {
    this._app
      .getAllCountries()

      .subscribe(
        (res) => {
          this.countries = res['data'];
          console.log(res['data']);
        },
        (err) => {
          console.log(err);
        }
      );
  }

  // Send Remote Link
  sendRemoteLink() {
    if (this.param1 === "UBO") {
      var id = this.UBOId
    } else if (this.param1 === "DIRECTOR") {
      var id = this.officerID1
    } else if (this.param1 === "OFFICER") {
      var id = this.officerID2
    }
    let obj;
    this._data.changeLoaderVisibility(true);
    this._app.startKYC(id).subscribe((res) => {
      // this._app.sendRemoteLink(id, obj).subscribe((response) => {
      this._data.changeLoaderVisibility(false);
      this._data.toastrShow(`Remote link has been sent`, 'success');
      const payload = {
        "message": 'Remote Link has been sent to the ' + res['data'].tpe.kysNo,
        "page": "Control Officer Remote Link",
        "geoLocation": null
      }
      this._app.feasAddActivityLog(localStorage.getItem("companyKysNo"), payload).subscribe();
      // });
    },
    (err: HttpErrorResponse) => {
      const payload = {
        "message": err,
        "page": "Control Officer Remote Link",
        "geoLocation": null
      }
      this._app.feasAddActivityLog(localStorage.getItem("companyKysNo"), payload).subscribe();
    });
  }

}


