import { Component, OnInit } from '@angular/core';
import { TabsModule } from 'ngx-bootstrap/tabs';
import { DataService } from 'src/app/services/data.service';
import { AppService } from "src/app/services/app.service";
import { PageChangedEvent } from 'ngx-bootstrap/pagination';
import { Router, ActivatedRoute, Params, NavigationEnd } from "@angular/router";
import {
  FormBuilder,
  FormGroup,
  Validators,
  FormControl,
  AbstractControl,
  FormArray,
  NgForm,

} from "@angular/forms";
import { first } from 'rxjs/operators';
import { CustomValidators } from "ng2-validation";
import { HttpClient, HttpErrorResponse, HttpEvent, HttpRequest } from '@angular/common/http';
import { style } from '@angular/animations';
import { element } from 'protractor';
import { Observable } from 'rxjs/internal/Observable';
import { KysCorporateFeasComponent } from '../kys-corporate-feas.component';

@Component({
  selector: 'app-corp-basic-info',
  templateUrl: './corp-basic-info.component.html',
  styleUrls: ['./corp-basic-info.component.scss']
})
export class CorpBasicInfoComponent implements OnInit {
  accountID: any;
  companyData: any;
  copmanyAccountInfoData: any;
  companyForm: FormGroup;
  submitData: any;

  constructor(
    private formBuilder: FormBuilder,
    private _data: DataService,
    private _app: AppService,
    public router: Router,
    public route: ActivatedRoute,
    private http: HttpClient)
     { }

  ngOnInit() {
    this.getAccountInfo()
    this.companyForm = this.formBuilder.group({
      nameOfEntity: [''],
      Jurisdiction: [''],
      cuurentStatus: [''],
      companyType: [''],
      branchCount: [''],
      pCompany: [''],
      cAddress: [''],
      region: [''],
      companyID: ['', Validators.required],
      country: [''],
      incorporateDate: [''],
      lei: [''],
      registryUrl: [''],
      pCompanyID: [''],
      locality: [''],
      postalCode: ['']
    })
  }

  getAccountInfo(){
    this._app.getAccountInfoforCorp().pipe(first()).subscribe((data) =>{
      this.copmanyAccountInfoData = data["data"]
      this.accountID = this.copmanyAccountInfoData.accountId
      console.log(this.accountID);
      this.getCompanyInfo(this.accountID)
    })

  }

  getCompanyInfo(id){
    this._app.getCompanyInfo(id).pipe(first()).subscribe((data) =>{
      this.companyData = data["data"]
      console.log("this is rge ",this.companyData)
      this._data.nameVal(this.companyData)
      this.companyForm.patchValue({
      nameOfEntity: this.companyData.companyName,
      Jurisdiction: this.companyData.tpe.jurisdiction.name,
      cuurentStatus: this.companyData.currentStatus,
      companyType: this.companyData.companyType,
      branchCount: this.companyData.branchCount,
      pCompany: this.companyData.parentCompanyName,
      cAddress: this.companyData.streetAddress,
      region: this.companyData.region,
      companyID: this.companyData.companyID,
      country: this.companyData.country.countryName,
      incorporateDate: this.companyData.incorporationDate,
      lei: this.companyData.lei,
      //registryUrl: this.companyData.,
      pCompanyID: this.companyData.parentCompanyID,
      locality: this.companyData.locality,
      postalCode: this.companyData.postalCode,
      })
      localStorage.setItem("companyKysNo", this.companyData.tpe.kysNo)
      console.log("hgfhjsg",this.companyData.country.countryCode);
      localStorage.setItem('countryCode', this.companyData.country.countryCode);
    })
  }

  postBasicInfo(){
    let abc = {
      "registrationName":null,
      "lei": this.companyForm.value.lei,
      "companyID": this.companyForm.value.companyID,
      "incorporationDate": this.companyForm.value.incorporateDate,
      "currentStatus": this.companyForm.value.cuurentStatus,
      "companyType": this.companyForm.value.companyType,
      "branchCount": parseInt(this.companyForm.value.branchCount),
      "parentCompanyName": this.companyForm.value.pCompany,
      "parentCompanyID": this.companyForm.value.pCompanyID,
      "streetAddress": this.companyForm.value.cAddress,
      "locality": this.companyForm.value.locality,
      "region": this.companyForm.value.region,
      "postalCode": this.companyForm.value.postalCode,
      "registeredAddressInFull": null
   }
   this.submitBasicInfo(abc, this.accountID)
  }

  submitBasicInfo(obj, id){
    this._data.changeLoaderVisibility(true);
    this._app.submitBasicInfoforCorp(obj, id).pipe(first()).subscribe((data) =>{
      this.submitData = data["data"]
      this._data.changeLoaderVisibility(false)
      this._data.toastrShow('Your Details have been saved successfully', `success`);
      this._data.basicVal(true);
      let status={
        "kysTpeCheckHeading":"BASIC_INFORMATION",
        "kysTpeCheckStatus":"PASSED"
      }
      this._app.sendStatus(status).subscribe(response=>{
        this._app.getStatus().subscribe(res=>{
          this._data.assignStatus(res)
        })
      })
      const payload = {
        "message": 'Company Details have been saved successfully',
        "page": "Company Basic Info Update",
        "geoLocation": null
      }
      this._app.feasAddActivityLog(localStorage.getItem("companyKysNo"), payload).subscribe();
    },
      (err: HttpErrorResponse) => {
        const payload = {
          "message": err,
          "page": "Company Basic Info Update",
          "geoLocation": null
        }
        this._app.feasAddActivityLog(localStorage.getItem("companyKysNo"), payload).subscribe();
      }
    )
  }

}
