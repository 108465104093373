import { Injectable } from "@angular/core";
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
} from "@angular/common/http";
import { Observable, throwError } from "rxjs";
import { Router } from "@angular/router";
import { catchError } from "rxjs/operators";
import { DataService } from "../services/data.service";
// import { AuthenticationService } from '../_services';

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
  constructor(
    private _data: DataService,
    private _router: Router // private authenticationService: AuthenticationService
  ) {}

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(
      catchError(err => {
        console.log(err);
        console.log(err.status);
        if (err.status == 401 || err.status == 403) {
          // auto logout if 401 response returned from api
          // this.authenticationService.logout();
          this._data.toastrShow("Not Logged In", "error");
          let errMessage =
            err.error.message || err.statusText || err["message"];
          let alertObj = {
            message: errMessage,
            status: "danger",
            autoDismiss: true,
            timeToDismiss: 5000,
          };
          // this._data.changePublicErr(errMessage);
          // this._data.displayUniversalAlert(alertObj);
          this._data.removeAllCookie();
          // this._data.changeLoaderVisibility(false);
          this._router.navigate(["/access-denied"]);
          return;
          // location.reload(true);
        }

        if (err.status !== 404) {
          const error = err.error.message || err.statusText || err["message"];
          this._data.toastrShow(error, "error");
          let alertObj = {
            message: error,
            status: "danger",
            autoDismiss: true,
            timeToDismiss: 5000,
          };
          // this._data.displayUniversalAlert(alertObj);
          this._data.changeLoaderVisibility(false);
        }

        const error = err.error.message || err.statusText || err["message"];
        return throwError(error);
      })
    );
  }
}
