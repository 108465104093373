import { Component, OnInit } from '@angular/core';
import { DataService } from '../../../services/data.service';
import { AppService } from '../../../services/app.service';
import { DatePipe } from '@angular/common';
import { 
  FormBuilder,
  FormGroup,
  Validators,
  FormControl,
  AbstractControl,
  FormArray,
  NgForm,
  
} from "@angular/forms";
import { Router, ActivatedRoute, Params, NavigationEnd } from "@angular/router";
import { finalize, first } from 'rxjs/operators';
import { CustomValidators } from "ng2-validation";
import { HttpErrorResponse } from '@angular/common/http';
import { style } from '@angular/animations';
import { element } from 'protractor';

@Component({
  selector: 'app-kys-co-basicinfo',
  templateUrl: './kys-co-basicinfo.component.html',
  styleUrls: ['./kys-co-basicinfo.component.scss']
})
export class KysCoBasicinfoComponent implements OnInit {
  accountID: any;
  countries: any;
  addMoreDetailsForm: FormGroup;
  controlOfficerType: any;

  constructor(
    private formBuilder: FormBuilder,
    private _data: DataService,
    private _app: AppService,
    public router: Router,
    public route: ActivatedRoute
  ) { }

  ngOnInit() {
    this.addMoreDetailsForm = this.formBuilder.group({
      firstName: [''],
      lastName: [''],
      dob: [''],
      maritalStatus: [''],
      phoneNo: [''],
      address: [''],
      ssn: [''],
      taxID: [''],
      percentage: [''],
      status: [''],
      designation: [''],
      email: [''],
      citizenship: [''],
  });

    this._app.getAllCountry().subscribe((response) =>{
      this.countries = response
    });

    this._app.getAllInfoforCO().subscribe((response) =>{
      this._data.uboDetailsVal(response);
      this.accountID = response.tpe.account.accountId
      this.controlOfficerType = response.controlOfficerType
      this.addMoreDetailsForm.patchValue({
        firstName: response.firstName,
        lastName: response.lastName,
        dob: response.dob,
        maritalStatus: response.maritalStatus,
        phoneNo: response.phoneNumber,
        address: response.residenceAddress,
        ssn: response.ssn,
        taxID: response.taxId,
        percentage: response.percentageOfOwnership,
        status: response.currentStatus,
        designation: response.designation,
        email: response.email,
        citizenship: response.citizenshipCountryCode,
      })
      if(response.tpe){
        localStorage.setItem("officerKysNo", response.tpe.kysNo)
      }
      console.log("hgfhjsg",response.tpe.country.countryCode);
      localStorage.setItem('countryCode', response.tpe.country.countryCode);
    })
    
  }

  getUpdateInfoOfficer(){
    const payload = {
      firstName: this.addMoreDetailsForm.value.firstName,
      lastName: this.addMoreDetailsForm.value.lastName,
      maritalStatus: this.addMoreDetailsForm.value.maritalStatus,
      dob: this.addMoreDetailsForm.value.dob,
      phoneNumber: this.addMoreDetailsForm.value.phoneNo,
      email: this.addMoreDetailsForm.value.email,
      residenceAddress: this.addMoreDetailsForm.value.address,
      ssn: this.addMoreDetailsForm.value.ssn,
      taxId: this.addMoreDetailsForm.value.taxID,
      percentageOfOwnership: this.addMoreDetailsForm.value.percentage,
      currentStatus: this.addMoreDetailsForm.value.status,
      designation: this.addMoreDetailsForm.value.designation,
      controlOfficerType: this.controlOfficerType,
      citizenshipCountryCode: this.addMoreDetailsForm.value.citizenship,
  };
    this._data.changeLoaderVisibility(true);
    this._app.getUpdateInfoOfficer(payload)
    .pipe(finalize(() => this._data.changeLoaderVisibility(false)))
    .subscribe((response) =>{
      this._data.toastrShow(`Information have been successfully updated`, 'success');
      const payload = {
        "message": 'Officer Information have been successfully updated',
        "page": "Officer Basic Info Update",
        "geoLocation": null
      }
      this._data.cobasicVal(true)
      this._app.feasAddActivityLog(localStorage.getItem("officerKysNo"), payload).subscribe();
    },
      (err: HttpErrorResponse) => {
        const payload = {
          "message": err,
          "page": "Officer Basic Info Update",
          "geoLocation": null
        }
        this._app.feasAddActivityLog(localStorage.getItem("officerKysNo"), payload).subscribe();
      }
    )
  }

}
