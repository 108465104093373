import { Component, Input, OnInit } from '@angular/core';
import { TabsModule } from 'ngx-bootstrap/tabs';
import { DataService } from 'src/app/services/data.service';
import { PageChangedEvent } from 'ngx-bootstrap/pagination';
import { FormBuilder, FormGroup } from '@angular/forms';
import { AppService } from 'src/app/services/app.service';
import { DatePipe } from '@angular/common';
import { finalize } from 'rxjs/operators';
import { HttpErrorResponse } from '@angular/common/http';

@Component({
  selector: 'app-kys-co-digital-upload',
  templateUrl: './kys-co-digital-upload.component.html',
  styleUrls: ['./kys-co-digital-upload.component.scss']
})
export class KysCoDigitalUploadComponent implements OnInit {
  uploadIndividualForm: FormGroup;
  accountID: any;
  allIdvCheckData: any;
  idvCheckforPrimary: any;
  addressCheckforPrimary: any;
  docNameforID: any;
  docNameforAddrr: any;
  docNameID: any;
  docIdID: any;
  docNameAddrr: any;
  docIdAddrr: any;
  idDocUploadedforIDData: any;
  states: any = [{}];
  stateVal: any = "";
  docList:any=[]
  @Input() isSelfie: boolean;
  tabs = [{ active: true }];
  openModal="";
  uploadedFile:any=[];
  checkId: any;
  documentNo:any=null;
  isssueDate:any=null;
  exparyDate:any=null;
  finish: boolean=false;
  
  constructor(
    private formBuilder: FormBuilder,
    private _app: AppService,
    private _data: DataService) 
  {}

  ngOnInit() {
    this.getStates();
    this.getAccountInfo();
    this.getAllChecks();
    this.getAvailableDocID();
    this.getAvailableDocAddrr();
  }

  getAllChecks(){
    this._app.getAllCheckforCO().subscribe((data) => {
      this.allIdvCheckData = data;
      
      // this.allIdvCheckData.forEach(element => {
      //   if(element.docType=='IDENTITY'){
      //     element.identityUpload=false
      //   }else if(element.docType=='ADDRESS'){
      //     element.addressUpload=false;
      //   }
      // });
    });
  }

  getAccountInfo(){
    this._app.getAllInfoforCO().subscribe((response) => {
      this.accountID = response.tpe.account.accountId
      this.getDocList();
    })
  }

  getStates(){
    this._app.getStates(localStorage.getItem('countryCode')).subscribe((response) => {
      this.states = response.data;
    });
  }
  
  getAvailableDocID() {
    this._app.getAvailableDoc('IDENTITY').subscribe((data) => {
        this.docNameforID = data;
    });
  }

  getAvailableDocAddrr() {
    this._app.getAvailableDoc('ADDRESS').subscribe((data) => {
        this.docNameforAddrr = data;
    });
  }

  checkNameID(event) {
    this.docNameID = event.docName;
    this.docIdID = event.kysDocumentID;
  }

  gotoBack() {
    this.openModal=null
  }

  storeFile(e) {
    for (var i = 0; i < e.target.files.length; i++) {
      this.uploadedFile.push(e.target.files[i]);
    }
  }

  uploadIdentity() {
    const idDocIssDInd = new DatePipe('en-US').transform(this.isssueDate, 'dd/MM/yyyy');
    const idDocExpDInd = new DatePipe('en-US').transform(this.exparyDate, 'dd/MM/yyyy');
    const formData = new FormData();
    formData.append('frontImage',this.uploadedFile[0]);
    formData.append('accountId', this.accountID);
    formData.append('docId', this.docIdID);
    formData.append('checkId', this.checkId);
    formData.append('docName', this.docNameID);
    formData.append('idNumber', this.documentNo);

    if (idDocIssDInd !== null) {
      formData.append('docIssueDate', idDocIssDInd);
    }
    if (idDocExpDInd !== null) {
      formData.append('docExpiryDate', idDocExpDInd);
    }
    this._data.changeLoaderVisibility(true);
    this._app.getDocumentUploadforCO(formData).subscribe((response) => {
      this._data.changeLoaderVisibility(false);
      this._data.toastrShow('Documents successfully uploaded', `success`);
      this.idDocUploadedforIDData = response['data'];
      this.documentNo=null;
      this.isssueDate=null;
      this.exparyDate=null;
      this.uploadedFile=[]
      // this.allIdvCheckData.forEach(element => {
      //   if(element.checkId===this.checkId){
      //     element.identityUpload=true
      //   }
      // });
      this.getAllChecks();
      this.getDocList();

    });
  } 

  getDocList(){
    this._app.getDocList(this.accountID).subscribe((response) => {
      this.docList = response
      this.docList=this.docList.filter(e=>(e.docType==='IDENTITY' || e.docType==='ADDRESS' ))
    });
  }

  changeState(event){
    this.stateVal=event.stateCode
  }
  
  checkNameAddrr(event) {
    this.docNameAddrr = event.docName;
    this.docIdAddrr = event.kysDocumentID;
  } 

  uploadAddress() {
    const formData = new FormData();
    formData.append('frontImage', this.uploadedFile);
    formData.append('accountId', this.accountID);
    formData.append('docId', this.docIdAddrr);
    formData.append('checkId', this.checkId);
    formData.append('docName', this.docNameAddrr);
    formData.append('stateCode', this.stateVal);
    this._data.changeLoaderVisibility(true);
    this._app.getDocumentUploadforCO(formData).subscribe((response) => {
      this._data.changeLoaderVisibility(false);
      this._data.toastrShow('Documents successfully uploaded', `success`);
      this.idDocUploadedforIDData = response['data'];
      this.uploadedFile=[]
      this.stateVal=null;
      this.getAllChecks();
      this.getDocList();
    });
  }

  getFinalSubmitforCO() {
    let obj;
    this._data.changeLoaderVisibility(true);

    if (!this.isSelfie) {
      this._app.getFinalSubmitforCO(obj).pipe(finalize(() => this._data.changeLoaderVisibility(false)))
        .subscribe((response) => {
          this._data.toastrShow(`All details submitted Successfully`, 'success')
          const payload = {
            "message": 'Officer Details Submitted Successfully',
            "page": "Officer Submit",
            "geoLocation": null
          }
          this._app.feasAddActivityLog(localStorage.getItem("officerKysNo"), payload).subscribe();
          this.finish=true;
        },
          (err: HttpErrorResponse) => {
            const payload = {
              "message": err,
              "page": "Officer Submit",
              "geoLocation": null
            }
            this._app.feasAddActivityLog(localStorage.getItem("officerKysNo"), payload).subscribe();
          }
        );
    }
    else {
      setTimeout(() => {
        this._data.changeLoaderVisibility(false);
        this._data.toastrShow('Documents successfully saved', `success`);
      }, 200);
    }
    this._data.codigitalVal(true)
  }

}
