import { Component, OnInit } from '@angular/core';
import { DataService } from 'src/app/services/data.service';
import { AppService } from '../../services/app.service';

@Component({
  selector: 'app-kys-corporate-feas',
  templateUrl: './kys-corporate-feas.component.html',
  styleUrls: ['./kys-corporate-feas.component.scss']
})
export class KysCorporateFeasComponent implements OnInit {

  basicDone:boolean=false;
  digitalDone:boolean=false;
  questionnaireDone:boolean=false;
  uboDone:boolean=false;
  showQuestionr:boolean = false;
  finish:boolean=false;
  terms:boolean=true;
  checkbox = false;
  checks:any=[];
  name: any='';
  constructor(
    private _app: AppService,
    private dataService: DataService
  ) { }

  ngOnInit() {
    this.dataService.basicValue.subscribe(res=>{
      this.basicDone=res
    })
    this.dataService.digitalValue.subscribe(res=>{
      this.digitalDone=res
    })
    this.dataService.questionValue.subscribe(res=>{
      this.questionnaireDone=res
    })
    this.dataService.uboValue.subscribe(res=>{
      this.uboDone=res
    })
    this.dataService.finishValue.subscribe(res=>{
      this.finish=res
    })
    let status={
      "kysTpeCheckHeading":"CONTROL_OFFICER",
      "kysTpeCheckStatus":"PASSED"
    }
    this._app.getStatus().subscribe((res:any)=>{
      this.dataService.assignStatus(res)
      if(res.data.length>0){
        this.terms=false
      }
    })
    this._app.getAccountInfoforCorp().subscribe((data) => {
      let copmanyAccountInfoData = data["data"]
      let accountID = copmanyAccountInfoData.accountId
      this._app.getAllCorpChecks(accountID).subscribe(res=>{
        this.checks=res["data"];
        if(!this.checks.questionCheck){
          let status={
            "kysTpeCheckHeading":"QUESTIONNAIRE",
            "kysTpeCheckStatus":"PASSED"
          }
          this._app.sendStatus(status).subscribe(response=>{
            
          })
        }
        if(!this.checks.documentCheck){
          let status={
            "kysTpeCheckHeading":"DIGITAL_UPLOAD",
            "kysTpeCheckStatus":"PASSED"
          }
          this._app.sendStatus(status).subscribe(response=>{
            
          })
        }
        if(this.checks.uboCheck==false && this.checks.directorCheck==false){
          let status={
            "kysTpeCheckHeading":"CONTROL_OFFICER",
            "kysTpeCheckStatus":"PASSED"
          }
          this._app.sendStatus(status).subscribe(response=>{
            
          })
        }

      })
    });
    this._app.getAllQuestionnaireCheck().subscribe((res) => {
      if(res && res.length){
        this.showQuestionr = true
      }
    });
    this.dataService.nameValue.subscribe(res=>{
      this.name=res
    })
  }

}
