export interface IRequestOptions {
  body?: any;
  headers?: HttpHeaders | { [header: string]: string | Array<string> };
  observe?: any;
  // params?: HttpParams | { [param: string]: string | Array<string> };
  reportProgress?: boolean;
  responseType?: "arraybuffer" | "blob" | "json" | "text";
  withCredentials?: boolean;
}

import { Injectable, Inject } from "@angular/core";
import {
  HttpClient,
  HttpRequest,
  HttpEvent,
  HttpEventType,
  HttpHeaders
} from "@angular/common/http";
import { map } from "rxjs/operators";
import { DataService } from "../services/data.service";

@Injectable({
  providedIn: "root"
})
export class AppService {
  token: any;

  constructor(
    private http: HttpClient,
    private _data: DataService,
    @Inject("API_URL") public API_URL: any
  ) {
    this.token = this._data.getCookiesByName("feas_token");
    console.log(this.token);
  }

  //Get i.e get Requests

  getDetails() {
    return this.http.get<any>(`${this.API_URL}/kys/v2.0/feas/userchecks`).pipe(
      map(res => {
        return res;
      })
    );
  }

  uploadDocuments(formData){
    console.log(formData);
    return this.http.post<any>(`${this.API_URL}/kys/v1.0/feas/userdocs/upload`, formData)
    .pipe(
      map(res => {
        return res;
      })
    );
  }

  updateIndividualInfo(obj, userName) {
    // let userName  = obj.userName ;
    return this.http
      .put<any>(`${this.API_URL}/faas/endusers/${userName}/info`, obj)
      .pipe(
        map(res => {
          return res;
        })
      );
  }

  getStates(countryCode){
    return this.http.get<any>(`${this.API_URL}/kys/v2.0/feas/province/country/${countryCode}`)
    .pipe(
      map(res => {
        return res;
      })
    );

  }

  getClientConfig() {
    return this.http.get<any>(`${this.API_URL}/kys/v2.0/feas/clients/faas/configs`).pipe(
      map(res => {
        return res;
      })
    );
  }

  getLangObj() {
    return this.http
      .get<any>(`${this.API_URL}/kys/v2.0/feas/languages?isoCode=en`)
      .pipe(
        map(res => {
          return res;
        })
      );
  }

  getUserAddress(checklinkguid) {
    return this.http
      .get<any>(`${this.API_URL}/faas/individualaddress/${checklinkguid}`)
      .pipe(
        map(res => {
          return res;
        })
      );
  }

  getAllowedDocs(countryName) {
    return this.http.get<any>(`${this.API_URL}/country/${countryName}`).pipe(
      map(res => {
        return res;
      })
    );
  }

  getCompanyInfo(id){
    return this.http.get<any>(`${this.API_URL}/kys/v2.0/feas/account/corporate/${id}`).pipe(
      map(res => {
        return res;
      })
    );
  }

  submitBasicInfoforCorp(obj, id){
    return this.http.put<any>(`${this.API_URL}/kys/v2.0/feas/account/corporate/${id}`, obj).pipe(
      map(res => {
        return res;
      })
    );
  }

  getIdDocUploadforCorp(obj){
    return this.http.post<any>(`${this.API_URL}/kys/v2.0/flexible/document/feas/upload`, obj)
    .pipe(
      map(res=>{
        return res;
      })
    )
  }

  getDetailsOfficers(obj){
    return this.http.post<any>(`${this.API_URL}/kys/v2.0/co/feas/add`, obj)
    .pipe(
      map(res=>{
        return res;
      })
    )
  }

  getMoreDetailsOfficers(obj){
    return this.http.put<any>(`${this.API_URL}/kys/v2.0/co/feas/update`, obj)
    .pipe(
      map(res=>{
        return res;
      })
    )
  }

  getViewDoc(id) {
    return this.http.get<any>(`${this.API_URL}/kys/v2.0/feas/document/211?url=`, id).pipe(
        map((res) => {
            return res;
        })
    );
}

  getDocName(id){
    return this.http.get<any>(`${this.API_URL}/kys/v2.0/feas/available/docs?type=${id}`).pipe(
      map(res => {
        return res;
      })
    );
  }

  getAccountInfoforCorp(){
    return this.http.get<any>(`${this.API_URL}/kys/v2.0/feas/account/info`).pipe(
      map(res => {
        return res;
      })
    );
  }

  getDocList(id){
    return this.http.get(`${this.API_URL}/kys/v2.0/feas/account/corporate/doc/check/${id}`).pipe(map((res: any) => res.data));
}

  getProvinceList(countryObj) {
    return this.http.post<any>(`${this.API_URL}/provinces`, countryObj).pipe(
      map(res => {
        return res;
      })
    );
  }

  getUserInfo(userInfo){
    return this.http.get<any>(`${this.API_URL}/kys/v1.0/v1feas/userinfo/${userInfo}`)
      .pipe(
        map(res => {
          return res;
        })
      );
  }

  getUserDocsDetails(checkGuid) {
    return this.http
      .get<any>(`${this.API_URL}/faas/userdocs/${checkGuid}/details`)
      .pipe(
        map(res => {
          return res;
        })
      );
  }

  getDocFromServer(checkGuid) {
    return this.http
      .get<any>(`${this.API_URL}/faas/userdocs/${checkGuid}/doc`)
      .pipe(
        map(res => {
          return res;
        })
      );
  }

  getAllCountries() {
    return this.http.get<any>(`${this.API_URL}/country/all `).pipe(
      map(res => {
        return res;
      })
    );
  }

  getPreviewData(hashId) {
    return this.http
      .get<any>(`${this.API_URL}/faas/userchecks/${hashId}/preview`)
      .pipe(
        map(res => {
          return res;
        })
      );
  }

  getCheckLevels() {
    return this.http.get<any>(`${this.API_URL}/authlinkfaas/checklevels`).pipe(
      map(res => {
        return res;
      })
    );
  }

  verifyUserData(userObj) {
    return this.http
      .post<any>(`${this.API_URL}/authlinkfaas/validateuser`, userObj)
      .pipe(
        map(res => {
          return res;
        })
      );
  }

  registerFEASUser(userObj) {
    return this.http
      .post<any>(`${this.API_URL}/authlinkfaas/register`, userObj)
      .pipe(
        map(res => {
          return res;
        })
      );
  }

  uploadVideoAndSelfie(formData) {
    // const HttpUploadOptions = {
    //   headers: new HttpHeaders({ "Content-Type": "multipart/form-data" })
    // }
    // let headers: Headers = new Headers({'Content-Type':'multipart/form-data'});
    // let roa: Re = new RequestOptions({headers: headers});
    // const HttpUploadOptions = {
    //   headers: new HttpHeaders({ "Accept": "application/json" })
    // }
    return this.http
      .post<any>(
        `${this.API_URL}/faas/userbiometric/liveness/video-selfie`,
        formData
      )
      .pipe(
        map(res => {
          return res;
        })
      );
  }

  finalSubmit(parentHashId ,logData) {
    return this.http
      .post<any>(`${this.API_URL}/faas/userchecks/${parentHashId}/submit`, logData)
      .pipe(
        map(res => {
          return res;
        })
      );
  }

  //Create i.e POST Requests

  triggerBiometricResult(obj) {
    return this.http
      .post<any>(`${this.API_URL}/faas/userbiometric/liveness/result`, obj)
      .pipe(
        map(res => {
          return res;
        })
      );
  }

  saveUserAddress(addressObj, checklinkguid) {
    return this.http
      .post<any>(
        `${this.API_URL}/faas/individualaddress/${checklinkguid}`,
        addressObj
      )
      .pipe(
        map(res => {
          return res;
        })
      );
  }

  saveUserDoc(docObj, checklinkguid) {
    return this.http
      .post<any>(
        `${this.API_URL}/faas/userdocs/upload/${checklinkguid}`,
        docObj
      )
      .pipe(
        map(res => {
          return res;
        })
      );
  }

  sendBiometricImage(obj) {
    return this.http
      .post<any>(`${this.API_URL}/faas/userbiometric/liveness/input`, obj)
      .pipe(
        map(res => {
          return res;
        })
      );
  }

  getLivenessDetails(livenessCheckID) {
    let headers = new HttpHeaders({
      "Content-Type": "application/octet-stream"
    });

    let authHttpOptions = {
      headers: headers,
      responseType: "blob"
    };

    // return this.http.get(
    //   `${this.API_URL}/userbiometric/doc/video/${checkGuid}` ,
    //      { headers: new HttpHeaders({"Content-Type": "application/octet-stream"}),
    //       responseType : "blob"}
    //    )
    //   .pipe(map(res => {
    //   return res;
    // }));

    return this.http
      .get(`${this.API_URL}/faas/userbiometric/doc/video/${livenessCheckID}`, {
        headers: new HttpHeaders({
          "Content-Type": "application/octet-stream"
        }),
        responseType: "blob"
      })
      .pipe(
        map(res => {
          return res;
        })
      );
  }

  getSelfieDetails(selfieCheckID) {
    return this.http
      .get<any>(
        `${this.API_URL}/faas/userbiometric/doc/selfie/${selfieCheckID}`
      )
      .pipe(
        map(res => {
          return res;
        })
      );
  }

  getAudios() {
    return this.http.get<any>(`${this.API_URL}/faas/audio/v1`).pipe(
      map(res => {
        return res;
      })
    );
  }

  //activity log
  saveUserActivityLog(logObj, username) {
    return this.http
      .post<any>(
        `${this.API_URL}/faas/endusers/activity/add/${username}`,
        logObj
      )
      .pipe(
        map(res => {
          return res;
        })
      );
  }
  // new activity log api
  saveUserActivityLogNew(logObj, username) {
    return this.http
      .post<any>(
        `${this.API_URL}/faas/endusers/v1.0/activity/add/${username}`,
        logObj
      )
      .pipe(
        map(res => {
          return res;
        })
      );
  }
  saveUserActivityLogGetstarted(logObj, username) {
    console.log("username",username);
    return this.http
      .post<any>(
        `${this.API_URL}/faas/endusers/${username}/getstarted`,
        logObj
      )
      .pipe(
        map(res => {
          return res;
        })
      );
  }
  saveUserActivityLogAgrement(logObj, username) {
    console.log("username",username);
    return this.http
      .post<any>(
        `${this.API_URL}/faas/endusers/${username}/aggrements`,
        logObj
      )
      .pipe(
        map(res => {
          return res;
        })
      );
  }

  // https://api.bigdatacloud.net/data/reverse-geocode-client?latitude=28.644800&longitude=77.216721&localityLanguage=en
  getLocation(lat,long) {
    return this.http.get<any>(`https://api.bigdatacloud.net/data/reverse-geocode-client?latitude=${lat}&longitude=${long}&localityLanguage=all`).pipe(
      map(res => {
        return res;
      })
    );
  }
  getSystemIp() {
    return this.http.get("https://cors-anywhere.herokuapp.com/http://api.ipify.org/?format=json");
  }

  // KYS_feas APIs
  getFeasSaveDetails(obj){
    return this.http.post<any>(`${this.API_URL}/kys/v1.0/v1feas/save`, obj)
    .pipe(
      map(res=>{
        return res;
      })
    )
  }
  getFeasSubmitDetails(){
    return this.http.post<any>(`${this.API_URL}/kys/v1.0/v1feas/submit`, null)
    .pipe(
      map(res=>{
        return res;
      })
    )
  }
  getFeasPersonalInfo(){
    return this.http.get<any>(`${this.API_URL}/kys/v1.0/v1feas/userinfo`)
    .pipe(
      map(res=>{
        return res;
      })
    )
  }
  getFeasContactInfo(){
    return this.http.get<any>(`${this.API_URL}/kys/v1.0/v1feas/contactinfo`)
    .pipe(
      map(res=>{
        return res;
      })
    )
  }
  getIdDocUpload(obj){
    return this.http.post<any>(`${this.API_URL}/kys/v1.0/v1feas/userdocs/upload`, obj)
    .pipe(
      map(res=>{
        return res;
      })
    )
  }
  getFeasUserCheck(){
    return this.http.get<any>(`${this.API_URL}/kys/v1.0/v1feas/userchecks`)
    .pipe(
      map(res=>{
        return res;
      })
    )
  }
  getFeasDocs(obj){
    return this.http.get<any>(`${this.API_URL}/kys/v1.0/v1feas/docs?docType=${obj}`)
    .pipe(
      map(res=>{
        return res;
      })
    )
  }

  getUBO() {
    return this.http.get<any>(`${this.API_URL}/kys/v2.0/co/feas/?type=UBO`)
      .pipe(map((res) => res.data));
  }

  getDirectors() {
    return this.http.get<any>(`${this.API_URL}/kys/v2.0/co/feas/?type=DIRECTOR`)
      .pipe(map((res) => res.data));
  }

  getComplianceOfficer() {
    return this.http.get<any>(`${this.API_URL}/kys/v2.0/co/feas/?type=OFFICER`)
      .pipe(map((res) => res.data));
  }

  getFinalSubmit(obj){
    return this.http.post<any>(`${this.API_URL}/kys/v2.0/feas/corporate/submit`, obj)
    .pipe(map((res) => res.data));
  }

  getAccountInfoForRetail(){
    return this.http.get<any>(`${this.API_URL}/kys/v2.0/feas/account/info`)
    .pipe(map((res) => res.data));
  }

  getIndividualInfoForRetail(id){
    return this.http.get<any>(`${this.API_URL}/kys/v2.0/feas/account/individual/${id}`)
    .pipe(map((res) => res.data));
  }

  getAllCountry(){
    return this.http.get<any>(`${this.API_URL}/kys/v2.0/feas/country/all`)
    .pipe(map((res) => res.data))
  }

  getUpdateIndividualInfo(accontId, personalId, obj){
    return this.http.put<any>(`${this.API_URL}/kys/v2.0/feas/account/individual/${accontId}/${personalId}`, obj)
    .pipe(map((res) => res.data));
  }

  getUpdateInfoOfficer(obj){
    return this.http.put<any>(`${this.API_URL}/kys/v2.0/co/feas/update-officer`, obj)
    .pipe(map((res) => res.data));
  }

  getUpdateAccountInfo(obj){
    return this.http.put<any>(`${this.API_URL}/kys/v2.0/feas/account/info`, obj)
    .pipe(map((res) => res.data));
  }

  getAllCheckByAccount(id){
    return this.http.get<any>(`${this.API_URL}/kys/v2.0/feas/account/checks/${id}`)
    .pipe(map((res) => res.data));
  }

  getAllCheckforCO(){
    return this.http.get<any>(`${this.API_URL}/kys/v2.0/co/controls-officer/get/checks`)
    .pipe(map((res) => res.data));
  }

  getAvailableDoc(id){
    return this.http.get<any>(`${this.API_URL}/kys/v2.0/feas/available/docs?type=${id}`)
    .pipe(map((res) => res.data));
  }

  getDocumentUpload(obj){
    return this.http.post<any>(`${this.API_URL}/kys/v2.0/feas/document/upload`, obj)
    .pipe(map((res) => res));
  }

  getDocumentAdHockUpload(obj){
    return this.http.post<any>(`${this.API_URL}/kys/v2.0/feas/ad-hock/upload`, obj)
    .pipe(map((res) => res));
  }

  getDocumentUploadforCO(obj){
    return this.http.post<any>(`${this.API_URL}/kys/v2.0/co/document/upload`, obj)
    .pipe(map((res) => res));
  }

  getAllInfoforCO(){
    return this.http.get<any>(`${this.API_URL}/kys/v2.0/co/feas/officer-info`)
    .pipe(map((res) => res.data[0]));
  }

  getFinalSubmitforCO(obj){
    return this.http.post<any>(`${this.API_URL}/kys/v2.0/co/submit`, obj)
    .pipe(map((res) => res.data))
  }

  getOverAllSubmitforCO(accountId: string, obj){
    return this.http.post<any>(`${this.API_URL}/kys/v2.0/co/overall-submit/${accountId}`, obj)
    .pipe(map((res) => res.data))
  }

  sendRemoteLink(id, obj){
    return this.http.post<any>(`${this.API_URL}/kys/v2.0/feas/account/corporate/co/remote-link/${id}`, obj)
    .pipe(map((res) => res.data))
  }

  startKYC(id){
    return this.http.get<any>(`${this.API_URL}/kys/v2.0/co/feas/start-kyc/${id}?isVersionV2=true`);
  }

  getRetailSubmit(obj){
    return this.http.post(`${this.API_URL}/kys/v2.0/feas/submit`, obj).pipe(map((res: any) => res.data))
  }


  getVideoChecks(){
    return this.http.get<any>(`${this.API_URL}/kys/v2.0/feas/officer/get-check`)
    .pipe(map((res) => res.data));
  }

  uploadVideoSelfieFile(formData){
    return this.http
    .post<any>(
      `${this.API_URL}/kys/v2.0/document/check/control-officer-video/upload`,formData
    )
  }


  getAllQuestionnaireCheck(){
    return this.http.get(`${this.API_URL}/kys/questionnaire/feas/get-all-checks`).pipe(map((res:any) => res.data));
  }

  submitAnswers(questionID, payload: Object){
    return this.http.post(`${this.API_URL}/kys/questionnaire/feas/submit-answer/${questionID}`, payload).pipe(map((res:any) => res.data));
  }

  uploadQuestionnaireDocs(questionID,payload: Object){
    return this.http.post(`${this.API_URL}/kys/questionnaire/feas/upload-document/${questionID}`, payload).pipe(map((res:any) => res.data));
  }

  getAllRetailCheck(accountId){
    return this.http.get(`${this.API_URL}/kys/v2.0/feas/account/checks/${accountId}`).pipe(map((res:any) => res.data)); 
  }

  feasAddActivityLog(kysNo, payload){
    return this.http.post(`${this.API_URL}/kys/v2.0/feas/add/${kysNo}`, payload);
  }


  getAllCorpChecks(accountId){
    return this.http.get(`${this.API_URL}/kys/v2.0/feas/get-customised/${accountId}`)
  }
  sendStatus(payload){
    return this.http.post(`${this.API_URL}/kys/v2.0/feas/mandatory/check`, payload);
  }
  getStatus(){
    return this.http.get(`${this.API_URL}/kys/v2.0/feas/get/checks`)
  }
}
