import { HttpErrorResponse } from '@angular/common/http';
import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { finalize } from 'rxjs/operators';
import { AppService } from 'src/app/services/app.service';
import { DataService } from 'src/app/services/data.service';

@Component({
  selector: 'app-quistionair-page',
  templateUrl: './quistionair-page.component.html',
  styleUrls: ['./quistionair-page.component.scss']
})
export class QuistionairPageComponent implements OnInit {
  isCharCountUp: boolean = false;
  files: any = [];
  questionnaireList: any = [];
  @Input() checks:any;
  constructor(private dataService: DataService, private appService: AppService) { }

  ngOnInit() {
    this.getAllCheck();
  }

  getAllCheck() {
    //this.dataService.changeLoaderVisibility(true);
    this.appService.getAllQuestionnaireCheck().pipe(finalize(() => this.dataService.changeLoaderVisibility(false))).subscribe((res) => {
      this.questionnaireList = res || [];
      console.log("response questionnaire: ",this.questionnaireList);
      this.questionnaireList.forEach(element => {
        if(element.inputData){
          element.questionnaire["answersLength"] = element.inputData.length
        }else{
          element.questionnaire["answersLength"] =0
        }
        element.questionnaire["answers"] = element.inputData
      });
    });
  }

  textQuestions(answers, questionCheckID, i) {
    this.questionnaireList[i].questionnaire.answersLength = answers.length;
  }

  cancel() {
    this.questionnaireList.filter(e => e.questionnaire.questionType != 'DOCUMENT').forEach(element => {
      element.inputData = '';
      element.questionnaire.answersLength = 0;
    });
  }

  getFiles(questionId,event) {
    this.questionnaireList.filter(e => e.questionCheckID===questionId).forEach(element => {
      element.file=event.target.files[0];
    });
    const formData = new FormData;
    formData.append('multipartFile', event.target.files[0])
    this.dataService.changeLoaderVisibility(true);
    this.appService.uploadQuestionnaireDocs(questionId,formData).pipe(finalize(() => this.dataService.changeLoaderVisibility(false))).subscribe(() => {
      this.dataService.toastrShow(`Document uploaded successfully`, 'success');
      this.getAllCheck()
    });
  }


  submitAnswers() {
    this.questionnaireList.filter(e => e.questionnaire.questionType != 'DOCUMENT').forEach(element => {
      const payload = {
        "inputData": element.inputData
      }
      this.dataService.changeLoaderVisibility(true);
      this.appService.submitAnswers(element.questionCheckID, payload).pipe(finalize(() => this.dataService.changeLoaderVisibility(false))).subscribe(() => {
        this.dataService.toastrShow(`Answer submitted successfully`, 'success');
        this.dataService.questionVal(true)
        let status={
          "kysTpeCheckHeading":"QUESTIONNAIRE",
          "kysTpeCheckStatus":"PASSED"
        }
        this.appService.sendStatus(status).subscribe(response=>{
          this.appService.getStatus().subscribe(res=>{
            this.dataService.assignStatus(res)
          })
        })
        element.inputData = '';
        element.questionnaire.answersLength = 0;
        this.getAllCheck()
      });
    });
  }


  checkedAnswer(qId,option){
    let index = this.questionnaireList.map(item=>item.questionnaire.kysQesID).indexOf(qId);
    let checks=this.questionnaireList[index].inputData.split(';')
    let str=''
    if(checks.includes(option)){
      checks=checks.filter(e=>e!=option)
      str=''
      str=checks.join(';')
      this.questionnaireList[index].inputData=str;

    }else{
      checks.push(option);
      str=''
      str=checks.join(';')
      this.questionnaireList[index].inputData=str;
    }
    
  }
  getFinalSubmit() {
    let abc;
    this.dataService.changeLoaderVisibility(true);
    this.appService.getFinalSubmit(abc).pipe(finalize(() => this.dataService.changeLoaderVisibility(false))).subscribe((response) => {
      this.dataService.toastrShow(`All details submitted Successfully`, 'success');
      
      const payload = {
        "message": 'Company details submitted Successfully',
        "page": "Company Final Submit",
        "geoLocation": null
      }
      this.dataService.finishVal(true)
      this.appService.feasAddActivityLog(localStorage.getItem("companyKysNo"), payload).subscribe();
    },
      (err: HttpErrorResponse) => {
        const payload = {
          "message": err,
          "page": "Company Final Submit",
          "geoLocation": null
        }
        this.appService.feasAddActivityLog(localStorage.getItem("companyKysNo"), payload).subscribe();
      }
    );
  }

  getFileName(str){
    return str[0].split('/')[str[0].split('/').length-1] 
  }
}
