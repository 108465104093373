import { AnimationGroupPlayer } from '@angular/animations/src/players/animation_group_player';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AppService } from 'src/app/services/app.service';

@Component({
  selector: 'app-welcome',
  templateUrl: './welcome2.component.html',
  styleUrls: ['./welcome2.component.scss']
})
export class Welcome2Component implements OnInit {
  userName: any='';
  constructor(private router: Router,
              private _app:AppService,
              private route: ActivatedRoute) { }

  checkbox = false; // bind first checkbox

  ngOnInit() {

    //this.getAccountInfo();
    this.route.queryParams.subscribe(({accountType}) => {
      
    });
  }
  
  getAccountInfo(){
    this.route.queryParams.subscribe(({accountType}) => {
      if (accountType === 'corporate') {
        
        this._app.getAccountInfoforCorp().subscribe(res=>{
          console.log("detauls ",res)
          if(res.client.userName){
            if(res.client)
            {
             this.userName=res.client.userName
            }
          }
    
        })
      } 
      else if(accountType === 'ubo' || accountType === 'director'){
        this._app.getAllInfoforCO().subscribe(res=>{
          console.log("detauls ",res)
          if(res){
            if(res.client){
            this.userName=res.client.userName
            }
          }
          
        })
      }
      else {
        
      }
    })
  }
  onClick() {
    this.route.queryParams.subscribe(({accountType}) => {
      if (accountType === 'corporate') {
        // Todo: add corporate route here        
        this.router.navigate(['kys-corporate-feas']);
      } else if(accountType === 'ubo'){
        this.router.navigate(['kys-control-officers-feas']);
      }else if(accountType === 'officer'){
        this.router.navigate(['kys-control-officers-feas']);
      }else if(accountType === 'director'){
        this.router.navigate(['kys-control-officers-feas']);
      } else if(accountType === 'questionnaire') {
        this.router.navigate(['questionair-page']);
      }
      else {
        this.router.navigate(['kys-retail-feas']);
      }
    })
  }
}
