
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpResponse,
  HttpClient,
} from "@angular/common/http";
import { Inject, Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { DataService } from "../services/data.service";
@Injectable()
export class ICOInterceptor implements HttpInterceptor {
  
	token : any ;

  constructor(
    private http: HttpClient,
    private _data : DataService,
  ){
      
  }

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    const headersConfig = {
      Accept: "application/json",
    };

    headersConfig["appsource"] = `FEAS`;
    
    let access_token = this._data.getCookiesByName("feas_token"); //multipart/form-data
    let auth_token = this._data.getCookiesByName("auth_token"); //multipart/form-data
    
    // console.log(":::::::::::::::::::::::", access_token);

    if (access_token && request.url.indexOf("video-selfie") > 0) {
      headersConfig["X-Auth-Token"] = `${access_token}`;
    }
    if (access_token && request.url.indexOf("/doc/video/") > 0) {
      headersConfig["X-Auth-Token"] = `${access_token}`;
      headersConfig["Content-Type"] = "application/octet-stream";
      console.log("Video Here");
      console.log(request);
    }


    if (access_token && request.url.indexOf("configs") > 0) {
      headersConfig["X-Auth-Token"] = `${access_token}`;
    }
    if (access_token && request.url.indexOf("/v1.0") > 0) {
      headersConfig["X-Auth-Token"] = `${access_token}`;
    }
    if (access_token && request.url.indexOf("/v2.0") > 0) {
      headersConfig["X-Auth-Token"] = `${access_token}`;
    }
    if (access_token && request.url.indexOf("kys") > 0) {
      headersConfig["X-Auth-Token"] = `${access_token}`;
    }
    
    

    // if (access_token && request.url.indexOf("video-selfie") < 0) {
    //   // headersConfig["Authorization"] = `Bearer ${access_token}` ;
    //   headersConfig["X-Auth-Token"] = `${access_token}`;
    //   headersConfig["Content-Type"] = `application/json`;
    //   console.log("I am running, ", access_token);
    // } else if (auth_token) {
    //   headersConfig["X-Auth-Token"] = `${auth_token}`;
    //   headersConfig["Content-Type"] = `application/json`;
    // }

    if(access_token && request.url.indexOf("feas/userdocs/upload") > 0){
      headersConfig["X-Auth-Token"] = `${access_token}`;
      // headersConfig["Content-Type"] = "application/octet-stream";
      // console.log(headersConfig["Content-Type"]);
      // headersConfig["Access-Control-Allow-Origin"]= '*';
    }
    console.log(request);
    request = request.clone({
      setHeaders: headersConfig,
    });

    return next.handle(request);
  }
}
