import { BrowserModule } from "@angular/platform-browser";
import {
  NgModule,
  APP_INITIALIZER,
  CUSTOM_ELEMENTS_SCHEMA,
  NO_ERRORS_SCHEMA,
} from "@angular/core";

//Pages
import { AppComponent } from "./app.component";
import { TestComponent } from "./pages/test/test.component";
import { Test2Component } from "./pages/test2/test2.component";
import { TermsComponent } from "./pages/terms/terms.component";
import { AccessDeniedComponent } from "./pages/access-denied/access-denied.component";
import { WelcomeComponent } from "./pages/welcome/welcome.component";
import { GettingStartedComponent } from "./pages/getting-started/getting-started.component";
import { KycComponent } from "./pages/kyc/kyc.component";

//Interceptor
import { ICOInterceptor, ErrorInterceptor } from "./interceptors";

//Modules
import {
  HttpClientModule,
  HttpClient,
  HTTP_INTERCEPTORS,
} from "@angular/common/http";
import { AppRoutingModule } from "./app-routing.module";
import {
  TranslateModule,
  TranslateLoader,
  TranslateService,
} from "@ngx-translate/core";
import { TranslateHttpLoader } from "@ngx-translate/http-loader";

import { CookieModule } from "ngx-cookie";
import { WebcamModule } from "ngx-webcam";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { ModalModule } from "ngx-bootstrap/modal";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { CustomFormsModule } from "ng2-validation";
import { ImageCropperModule } from "ngx-image-cropper";
import { ToastrModule } from "ngx-toastr";
import { BsDatepickerModule } from "ngx-bootstrap/datepicker";
import { TabsModule } from "ngx-bootstrap/tabs";

//Services
import { UiStateService } from "./services/ui-state.service";
import { DataService } from "./services/data.service";
import { AppService } from "./services/app.service";
import { KycService } from "./services/kyc.service";
import { SelfieService } from "./services/selfie.service";
import { Ng2DeviceDetectorModule } from "ng2-device-detector";
//Guards
import { AuthGuard } from "./guards/auth.guard";
import { DeactivateGuard } from "./guards/deactivate.guard";
import { KycGuard } from "./guards/kyc.guard";

//Pipes
import { ObjArrPipe } from "./pipes/obj-arr.pipe";
import { NoSanitizePipe } from "./pipes/no-sanitize.pipe";

//Components
import { KycSvgComponent } from "./components/kyc-svg/kyc-svg.component";
import { IdCheckComponent } from "./components/id-check/id-check.component";
import { AddressCheckComponent } from "./components/address-check/address-check.component";
import { SelfieCheckComponent } from "./components/selfie-check/selfie-check.component";
import { PreviewComponent } from "./components/preview/preview.component";

// Shared
import { FeasLoaderComponent } from "./shared/feas-loader/feas-loader.component";
import { FeasModalComponent } from "./shared/feas-modal/feas-modal.component";

//directives
import { DndDirective } from "./directives/dnd.directive";
import { ShowImageDirective } from "./directives/show-image.directive";
import { KysVerificationComponent } from "./pages/kys-verification/kys-verification.component";
import { PaginationModule } from "ngx-bootstrap/pagination";
import { SignaturePadModule } from "@ng-plus/signature-pad";
import { KysHeaderComponent } from "./pages/kys-verification/kys-header/kys-header.component";
import { KysSidebarComponent } from "./pages/kys-verification/kys-sidebar/kys-sidebar.component";
import { KysBasicInfoComponent } from "./pages/kys-verification/kys-basic-info/kys-basic-info.component";
import { KysOfficersComponent } from "./pages/kys-verification/kys-officers/kys-officers.component";
import { KysRetailFeasComponent } from "./pages/kys-retail-feas/kys-retail-feas.component";
import { KysRetailBasicInfoComponent } from "./pages/kys-retail-feas/kys-retail-basic-info/kys-retail-basic-info.component";
import { KysRetailDigitalUploadsComponent } from "./pages/kys-retail-feas/kys-retail-digital-uploads/kys-retail-digital-uploads.component";
import { KysDocumentVerificationComponent } from "./pages/kys-retail-feas/kys-document-verification/kys-document-verification.component";
import { KysBinaryChecksComponent } from "./pages/kys-retail-feas/kys-binary-checks/kys-binary-checks.component";
import { KysAMLChecksComponent } from "./pages/kys-retail-feas/kys-aml-checks/kys-aml-checks.component";
import { KysAdditionalInfoComponent } from "./pages/kys-retail-feas/kys-additional-info/kys-additional-info.component";
import { Welcome2Component } from "./pages/welcome2/welcome2.component";
import { KysControlOfficersComponent } from "./pages/kys-retail-feas/kys-control-officers/kys-control-officers.component";
import { KysCorporateFeasComponent } from "./pages/kys-corporate-feas/kys-corporate-feas.component";
import { CorpBasicInfoComponent } from "./pages/kys-corporate-feas/corp-basic-info/corp-basic-info.component";
import { CorpDigitalUploadComponent } from "./pages/kys-corporate-feas/corp-digital-upload/corp-digital-upload.component";
import { CorpControlOfficersComponent } from "./pages/kys-corporate-feas/corp-control-officers/corp-control-officers.component";
import { AddDetailsComponent } from "./pages/kys-corporate-feas/corp-control-officers/add-details/add-details.component";
import { KysControlOfficersFeasComponent } from "./pages/kys-control-officers-feas/kys-control-officers-feas.component";
import { KysCoBasicinfoComponent } from "./pages/kys-control-officers-feas/kys-co-basicinfo/kys-co-basicinfo.component";
import { KysCoDigitalUploadComponent } from "./pages/kys-control-officers-feas/kys-co-digital-upload/kys-co-digital-upload.component";
import { QuistionairPageComponent } from "./pages/quistionair-page/quistionair-page.component";
import { NgSelectModule } from "@ng-select/ng-select";

export function createTranslateLoader(http: HttpClient) {
  return new TranslateHttpLoader(http, "./assets/i18n/", ".json");
  // return new TranslateHttpLoader(http, AppConfig.API_URL+'/static/i18n/', '.json');
}

@NgModule({
  declarations: [
    AppComponent,
    TestComponent,
    Test2Component,
    ObjArrPipe,
    NoSanitizePipe,
    AccessDeniedComponent,
    WelcomeComponent,
    KycSvgComponent,
    TermsComponent,
    GettingStartedComponent,
    IdCheckComponent,
    AddressCheckComponent,
    SelfieCheckComponent,
    KycComponent,
    FeasLoaderComponent,
    FeasModalComponent,
    DndDirective,
    ShowImageDirective,
    PreviewComponent,
    KysVerificationComponent,
    KysRetailFeasComponent,
    KysHeaderComponent,
    KysSidebarComponent,
    KysBasicInfoComponent,
    KysRetailBasicInfoComponent,
    KysRetailDigitalUploadsComponent,
    KysDocumentVerificationComponent,
    KysAMLChecksComponent,
    KysBinaryChecksComponent,
    KysAdditionalInfoComponent,
    KysOfficersComponent,
    Welcome2Component,
    KysControlOfficersComponent,
    KysCorporateFeasComponent,
    CorpBasicInfoComponent,
    CorpDigitalUploadComponent,
    CorpControlOfficersComponent,
    AddDetailsComponent,
    KysControlOfficersFeasComponent,
    KysCoBasicinfoComponent,
    KysCoDigitalUploadComponent,
    QuistionairPageComponent,
  ],
  imports: [
    BrowserModule,
    HttpClientModule,
    AppRoutingModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: createTranslateLoader,
        deps: [HttpClient],
      },
    }),
    ToastrModule.forRoot({
      timeOut: 5000,
      positionClass: "toast-top-right",
      preventDuplicates: true,
      closeButton: true,
    }),
    CookieModule.forRoot(),
    ModalModule.forRoot(),
    FormsModule,
    ReactiveFormsModule,
    Ng2DeviceDetectorModule.forRoot(),
    WebcamModule,
    BrowserAnimationsModule,
    CustomFormsModule,
    ImageCropperModule,
    BsDatepickerModule.forRoot(),
    TabsModule.forRoot(),
    PaginationModule.forRoot(),
    SignaturePadModule,
    NgSelectModule,
  ],
  providers: [
    AuthGuard,
    DeactivateGuard,
    KycGuard,
    UiStateService,
    TranslateService,
    DataService,
    KycService,
    SelfieService,
    AppService,
    { provide: "API_URL", useValue: "https://api.sandbox.staging.kychub.com" }, // Sandbox Staging
    // { provide: "API_URL", useValue: "https://api.legacy.sandbox.kychub.co" }, // Sandbox Prod
    {
      provide: "User_Placeholder",
      useValue:
        "https://www.hosted.kychub.com/dashboard/assets/img/avatar/avatar.png",
    },
    { provide: HTTP_INTERCEPTORS, useClass: ICOInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA, NO_ERRORS_SCHEMA],
  entryComponents: [TestComponent, Test2Component, FeasModalComponent],
  bootstrap: [AppComponent],
})
export class AppModule {}
